@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Inter:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
  font-size: 16px;
  line-height: 1.6;
}

code {
  font-family: 'Fira Code', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Fira Code', monospace;
  font-weight: 600;
  letter-spacing: -0.5px;
}

p {
  font-weight: 400;
}

a {
  color: #0077cc;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #005fa3;
  text-decoration: underline;
}